<template>
        <div class="row mt-4">
            <div class="col-3 d-flex justify-content-center align-items-center">
              <div
                class="icon d-flex justify-content-center align-items-center"
              >
                <img :src="logo" alt="icon" />
              </div>
            </div>
            <div class="col-9 desc">
              <div class="title">{{ title }}</div>
              <div class="desc">
                {{ desc }}
              </div>
            </div>
          </div>
</template>

<script>
export default {
    props:['title', 'desc', 'logo']
}
</script>

<style>

</style>