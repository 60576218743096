import Vue from 'vue';
import App from './App.vue';
import store from "./store";
import router from "./router";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min.js";
import { library } from '@fortawesome/fontawesome-svg-core';
import { faPlay, faChevronLeft, faMapMarkedAlt, faChevronRight, faCircle, faStar, faMapMarker, faPhone, faEnvelope, faMapMarkerAlt, faTimes, faCheckCircle, faLanguage } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram, faGithub, faLinkedin, faTwitter } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueGeolocation from 'vue-browser-geolocation';
import * as VueGoogleMaps from 'vue2-google-maps'
import i18n from "./I18n";
import mixnis from "./mixins";
library.add(faFacebook, faInstagram, faGithub, faLinkedin, faTwitter, faPlay, faChevronLeft, faMapMarkedAlt, faChevronRight, faCircle, faStar, faMapMarker, faPhone, faEnvelope, faMapMarkerAlt, faTimes, faCheckCircle, faLanguage);
Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyCDk3NYDa3uhYx9-mXwXeCDi2yw4Q1ZaMU',
    region: localStorage.getItem('lang') || mixnis.computed.osLang(),
    language: localStorage.getItem('lang') || mixnis.computed.osLang(),
    libraries: 'places',
  },
})
Vue.config.productionTip = false;
Object.defineProperty(Vue.prototype, '$activeLang', {
  get() {
    return localStorage.getItem("lang") || mixnis.computed.osLang();
  }
});
Vue.use(VueGeolocation);
Vue.mixin(mixnis);
Vue.component("fa", FontAwesomeIcon);
i18n.locale = localStorage.getItem("lang") || mixnis.computed.osLang()
new Vue({
  store,
  router,
  i18n,
  render: h => h(App),
}).$mount('#app');
