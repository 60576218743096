<template>
  <div :class="['counts-sec', $route.name != 'Home' ? 'py-5 my-5' : '']">
    <div class="container">
            <div class="header text-center">
        <div class="sec-title">{{ $t("weletthenumberstalk") }}</div>
        <div class="sec-desc w-lg-50 mx-auto mb-4 mt-2">{{ $t("weletthenumberstalkDesc") }}</div>
      </div>
      <div class="row counts">
        <div
          class="col-sm-6 col-md-3 col-12 count"
          v-for="(count, cind) in counts"
          :key="cind"
        >
          <div class="sec-title en" dir="ltr">
            {{ count.count }}
          </div>
          <div class="sec-desc">
            {{ count.label }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      counts: [
        {
          count: "+165k",
          label: this.$t("hoursOfCoding"),
        },

        {
          count: "25",
          label: this.$t("softwareEng"),
        },
        {
          count: "6",
          label: this.$t("countries"),
        },
        {
          count: "+70",
          label: this.$t("porjects"),
        },
      ],
    };
  },
};
</script>

<style>
</style>