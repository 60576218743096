<template>
  <transition
    enter-active-class="animate__animated animate__fadeInUp"
    leave-active-class="animate__animated animate__fadeOutDown"
  >
    <div class="project" v-if="myProject">
      <img :src="project.cover" :alt="'Project ' + project.title + ' Cover'" />
      <div
        class="
          proj-info
          text-center
          d-flex
          align-items-center
          justify-content-center
        "
      >
        <div class="proj-title">
          {{ project.title }}
        </div>
        <!-- <div class="proj-desc">
          {{ project.desc }}
        </div>
        <button class="btn-pri-light" @click="openProject(project)">
          {{ $t("seeProject") }}
        </button> -->
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  props: ["project"],
  data() {
    return {
      myProject: null,
    };
  },
  created() {
    this.myProject = false;
    setTimeout(() => {
      this.myProject = true;
    }, 500);
  },
  methods: {
    openProject(proj) {
      this.$emit("openProject", proj);
    },
  },
  watch: {
    project(newProj) {
      if (newProj) {
        this.myProject = false;
        setTimeout(() => {
          this.myProject = true;
        }, 550);
      }
    },
  },
};
</script>
