import Vuex from "vuex";
import Vue from "vue";
import i18n from "../I18n";
// Vue.use(i18n);
Vue.use(Vuex);
// Vue.use();
i18n.locale = localStorage.getItem("lang")
// const that = this;
export default new Vuex.Store({
  state: {
    appName: "Optivius",
    services: [
      {
        logo: "web",
        title: i18n.t('webDev'),
        desc: i18n.t('webDevDesc'),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        id: 1,
      },
      {
        logo: "mobile",
        title: i18n.t('mobDev'),
        desc: i18n.t('mobDevDesc'),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        id: 2,
      },
      {
        logo: "edit",
        title: i18n.t('uiDes'),
        desc: i18n.t('uiDesDesc'),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        id: 3,
      },
      {
        logo: "cloud",
        title: i18n.t('devOps'),
        desc: i18n.t('devOpsDesc'),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        id: 4,
      },
      {
        logo: "switch",
        title: i18n.t('softwarearchitecture'),
        desc: i18n.t('softwarearchitectureDesc'),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        id: 5,
      },
      {
        logo: "data",
        title: i18n.t('dataanalysis'),
        desc: i18n.t('dataanalysisDesc'),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        id: 6,
      },
      {
        logo: "cyber",
        title: i18n.t('cybersecurity'),
        desc: i18n.t('cybersecurityDesc'),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        id: 6,
      },
    ],
    projects: [
      {
        cover: require("../assets/images/Bein Medical.png"),
        title: "Bein Medicine",
        desc: i18n.t("testTxt").slice(0, 34),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        tabId: [4],
        link: "https://google.com",
      },
      {
        cover: require("../assets/images/Dokan.png"),
        title: i18n.t("dokanMart"),
        desc: i18n.t("testTxt").slice(0, 34),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        tabId: [2, 3],
        link: "https://google.com",
      },
      {
        cover: require("../assets/images/Horizon Real Estate.png"),
        title: i18n.t("horizonRealEstate"),
        desc: i18n.t("testTxt").slice(0, 34),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        tabId: [5, 4],
      },
      {
        cover: require("../assets/images/Your Decisions.png"),
        title: i18n.t("yourDecisionApp"),
        desc: i18n.t("testTxt").slice(0, 34),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        tabId: [7],
        link: "https://google.com",
      },
      {
        cover: require("../assets/images/Souq Jinan.png"),
        title: i18n.t("sooqJinan"),
        desc: i18n.t("testTxt").slice(0, 34),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        tabId: [2, 3],
        link: "https://google.com",
      },
      {
        cover: require("../assets/images/Sefara.png"),
        title: i18n.t("egyptEmbassyGermany"),
        desc: i18n.t("testTxt").slice(0, 34),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        tabId: [10],
        link: "https://google.com",
      },
      {
        cover: require("../assets/images/Copacabana.png"),
        title: i18n.t("copaCabana"),
        desc: i18n.t("testTxt").slice(0, 34),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        tabId: [2],
        link: "https://google.com",
      },
      {
        cover: require("../assets/images/360 Fm.png"),
        title: i18n.t("360FMKuwait"),
        desc: i18n.t("testTxt").slice(0, 34),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        tabId: [7],
        link: "https://google.com",
      },
      {
        cover: require("../assets/images/projects/sewarna.jpeg"),
        title: i18n.t("sowarna"),
        desc: i18n.t("testTxt").slice(0, 34),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        tabId: [2],
        link: "https://google.com",
      },
      {
        cover: require("../assets/images/Die Sprachbruke.png"),
        title: i18n.t("dieSprachbruke"),
        desc: i18n.t("testTxt").slice(0, 34),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        tabId: [11],
        link: "https://google.com",
      },
      {
        cover: require("../assets/images/Strata.png"),
        title: i18n.t("strataEgypt"),
        desc: i18n.t("testTxt").slice(0, 34),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        tabId: [8, 4],
        link: "https://google.com",
      },
      {
        cover: require("../assets/images/projects/sanwnaw-web.jpeg"),
        title: i18n.t("Sanawnaw"),
        desc: i18n.t("testTxt").slice(0, 34),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        tabId: [2],
        link: "https://google.com",
      },
      // {
      //   cover: require("../assets/images/proj-1.png"),
      //   title: i18n.t("mashCosmetics"),
      //   desc: i18n.t("testTxt").slice(0, 34),
      //   imgs: [
      //     require("../assets/images/iphone.png"),
      //     require("../assets/images/iphones.png"),
      //     require("../assets/images/iphonex.png"),
      //   ],
      //   tabId: [2],
      //   link: "https://google.com",
      // },
      // {
      //   cover: require("../assets/images/proj-1.png"),
      //   title: i18n.t("dokanDeliveryApp"),
      //   desc: i18n.t("testTxt").slice(0, 34),
      //   imgs: [
      //     require("../assets/images/iphone.png"),
      //     require("../assets/images/iphones.png"),
      //     require("../assets/images/iphonex.png"),
      //   ],
      //   tabId: [2],
      //   link: "https://google.com",
      // },
      // {
      //   cover: require("../assets/images/proj-1.png"),
      //   title: i18n.t("alNassrClub"),
      //   desc: i18n.t("testTxt").slice(0, 34),
      //   imgs: [
      //     require("../assets/images/iphone.png"),
      //     require("../assets/images/iphones.png"),
      //     require("../assets/images/iphonex.png"),
      //   ],
      //   tabId: [6],
      //   link: "https://google.com",
      // },
      {
        cover: require("../assets/images//projects/nasr-web.jpeg"),
        title: i18n.t("Al-Nassr Saudi Club"),
        desc: i18n.t("testTxt").slice(0, 34),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        tabId: [6],
        link: "https://google.com",
      },
      {
        cover: require("../assets/images/Dohler.png"),
        title: i18n.t("doehlerEgyptHRM"),
        desc: i18n.t("testTxt").slice(0, 34),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        tabId: [4, 9],
        link: "https://google.com",
      },
      {
        cover: require("../assets/images/pos.png"),
        title: i18n.t("digitalekaPOS"),
        desc: i18n.t("testTxt").slice(0, 34),
        imgs: [
          // require("../assets/images/iphone.png"),
          // require("../assets/images/iphones.png"),
          // require("../assets/images/iphonex.png"),
        ],
        tabId: [2, 3],
        link: "https://google.com",
      },
      // {
      //   cover: require("../assets/images/PO.png"),
      //   title: i18n.t("digitalekaPOS"),
      //   desc: i18n.t("testTxt").slice(0, 34),
      //   imgs: [
      //     require("../assets/images/iphone.png"),
      //     require("../assets/images/iphones.png"),
      //     require("../assets/images/iphonex.png"),
      //   ],
      //   tabId: [2, 3],
      //   link: "https://google.com",
      // },
    ],
    solutions: [
      {
        id: 1,
        img: require("../assets/images/Dohler.png"),
        title: i18n.t('hrmSys'),
        desc: i18n.t('hrmSysDesc'),
      },
      {
        id: 2,
        img: require("../assets/images/ecommerce.png"),
        title: i18n.t('eComm'),
        desc: i18n.t('eCommDesc'),
      },
      {
        id: 3,
        img: require("../assets/images/Horizon Real Estate.png"),
        title: i18n.t('realEstate'),
        desc: i18n.t('realEstateDesc'),
      },
      {
        id: 4,
        img: require("../assets/images/Bein Medical.png"),
        title: i18n.t('Learningmanagement'),
        desc: i18n.t('LearningmanagementDesc'),
      },
      {
        id: 5,
        img: require("../assets/images/360 Fm.png"),
        title: i18n.t('audVidStream'),
        desc: i18n.t('audVidStreamDesc'),
      },
      // {
      //   id: 6,
      //   img: require("../assets/images/pos.png"),
      //   title: i18n.t('pointOfSale'),
      //   desc: i18n.t('pointOfSaleDesc'),
      // },
      {
        id: 7,
        img: require("../assets/images/Copacabana.png"),
        title: i18n.t('restaurantsolutions'),
        desc: i18n.t('restaurantsolutionsDesc'),
      },
      {
        id: 8,
        img: require("../assets/images/projects/sanwnaw-web.jpeg"),
        title: i18n.t('bookingsystem'),
        desc: i18n.t('bookingsystemDesc'),
      },
    ],

  },
  mutations: {},
  actions: {},
  getters: {
    services: state => state.services,
    solutions: state => state.solutions,
    projects: state => state.projects
  },
  modules: {},
});
