<template>
  <div class="home-page">
    <Main-section />
    <HowItWorks />
    <About-us />
    <Services />
    <Portfolio />
    <Counts />
    <Solutions-section />
    <!-- <Testimonials /> -->
    <Parteners />
    <News-letter />
  </div>
</template>

<script>
import MainSection from "../components/Layout/MainSection.vue";
import HowItWorks from "../components/Layout/HowItWorks.vue";
import AboutUs from "../components/Layout/AboutUs.vue";
import NewsLetter from "../components/Layout/NewsLetter.vue";
import Parteners from "../components/Layout/Parteners.vue";
import Counts from "../components/Layout/Counts.vue";
import Services from "../components/Layout/Services.vue";
// import Testimonials from "../components/Layout/Testimonials.vue";
import Portfolio from "../components/Layout/Portfolio.vue";
import SolutionsSection from "../components/Layout/SolutionsSection.vue";
export default {
  components: {
    HowItWorks,
    MainSection,
    AboutUs,
    NewsLetter,
    Parteners,
    Counts,
    Services,
    // Testimonials,
    Portfolio,
    SolutionsSection,
  },
};
</script>
