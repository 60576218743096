<template>
  <div :class="['portfolio-sec', $route.name == 'Home' ? '' : 'pt-5']">
    <div class="container">
      <div class="header text-center" v-if="$route.name == 'Home'">
        <div class="sec-desc red">{{ $t("Successstories") }}</div>
        <div class="sec-title">{{ $t("ourPortfolio") }}</div>
        <div class="sec-desc">{{ $t("ourPortfolioDesc") }}</div>
      </div>
      <div class="position-relative">
        <Swiper
          :dir="$activeLang == 'ar' ? 'rtl' : 'ltr'"
          ref="tabsSwiper"
          :options="swiperOptions"
          :class="['tabs', $activeLang, $route.name == 'Home' ? '' : 'my-4']"
        >
          <Swiper-slide
            v-for="tab in tabs"
            :key="tab.id"
            @click.native="activeTab = tab.id"
            :class="['tab', activeTab == tab.id ? 'active' : '']"
          >
            <!-- tab.name == $t('all') ? '' : 'en', -->
            <div role="button">
              <span>
                {{
                  tab.name == $t("all")
                    ? tab.name + " (" + projects.length + ")"
                    : tab.name
                }}
              </span>
            </div>
          </Swiper-slide>
        </Swiper>
        <swiper-actions @next="nextSlide" @prev="prevSlide" :forTabs="true" />
      </div>

      <div class="projects-section pb-4">
        <Project-card
          :project="proj"
          v-for="(proj, projInd) in filterProjects"
          :key="projInd"
        />
        <!-- @openProject="toggelModal" -->
      </div>
      <!-- <router-link :to="{ name: 'Portfolio' }" v-if="$route.name == 'Home'">
        <button class="btn-pri-dark mx-auto my-4 d-block">
          {{ $t("allProjects") }}
        </button>
      </router-link> -->
    </div>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <modal
        :project="modalData"
        :link="link"
        :btnName="btnName"
        v-if="activeModal"
        @closeModal="toggelModal"
      />
    </transition>
  </div>
</template>

<script>
import ProjectCard from "./projectCard.vue";
import Modal from "../Layout/Modal.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.min.css";
import { mapGetters } from "vuex";
import SwiperActions from "./SwiperActions.vue";
export default {
  components: { ProjectCard, Modal, Swiper, SwiperSlide, SwiperActions },
  data() {
    return {
      swiperOptions: {
        slidesPerView: "auto",
        speed: 500,
      },
      tabs: [
        {
          name: this.$t("all"),
          id: 1,
        },
        {
          name: this.$t("ecommerce"),
          id: 2,
        },
        {
          name: this.$t("retail"),
          id: 3,
        },
        {
          name: this.$t("corporate"),
          id: 4,
        },
        {
          name: this.$t("realEstate"),
          id: 5,
        },
        {
          name: this.$t("sports"),
          id: 6,
        },
        {
          name: this.$t("media"),
          id: 7,
        },
        {
          name: this.$t("engineering"),
          id: 8,
        },
        {
          name: this.$t("HRM"),
          id: 9,
        },
        {
          name: this.$t("government"),
          id: 10,
        },
        {
          name: this.$t("education"),
          id: 11,
        },
      ],
      activeTab: 1,
      modalData: null,
      link: null,
      btnName: null,
      activeModal: false,
    };
  },
  methods: {
    changeTab(id) {
      this.activeTab = id;
    },
    toggelModal(proj) {
      this.activeModal = !this.activeModal;
      if (proj) {
        this.modalData = proj;
        this.btnName = this.$t("seeProject");
        this.link = "https://google.com";
      }
    },
    nextSlide() {
      this.tabsSwiper.slideNext(500);
      this.activeTab = ++this.activeTab;
      if (this.activeTab > this.tabs.length) this.activeTab = 1;
      // if (this.activeTab == 6) this.activeTab = 7;
      // console.log(this.activeTab);
    },
    prevSlide() {
      this.tabsSwiper.slidePrev(500);
      this.activeTab = --this.activeTab;
      if (this.activeTab < 1) this.activeTab = this.tabs.length;
      // if (this.activeTab == 6) this.activeTab = 5;
      // console.log(this.activeTab);
    },
  },
  watch: {
    activeTab(nind) {
      const index = this.tabs.findIndex((tab) => tab.id == nind);
      this.tabsSwiper.slideTo(index, 1000);
    },
  },
  computed: {
    ...mapGetters(["projects"]),
    filterProjects() {
      if (this.activeTab == 1) {
        if (this.$route.name == "Home") {
          return this.projects.slice(0, 6);
        } else {
          return this.projects;
        }
      } else {
        let filProj = [];
        this.projects.filter((proj) => {
          proj.tabId.forEach((id) => {
            if (id == this.activeTab) {
              filProj.push(proj);
            }
          });
          return filProj;
        });
        if (this.$route.name == "Home") {
          return filProj.slice(0, 6);
        } else {
          return filProj;
        }
      }
    },
    tabsSwiper() {
      return this.$refs.tabsSwiper.$swiper;
    },
  },
};
</script>

<style>
</style>