<template>
  <div class="mx-auto my-3 solution-card" @click="goToSolution(solution)">
    <!-- @mouseenter="expandData"
    @mouseleave="dispandData" -->
    <img :src="solution.img" alt="Solution Image" class="w-100" />
    <h4 class="fw-bold" v-html="solution.title"></h4>
    <p>
      {{
        `${solution.desc.slice(0, sliceVal)} ${
          solution.desc.length >= sliceVal ? dots : ""
        }`
      }}
    </p>
  </div>
</template>

<script>
export default {
  props: ["solution"],
  data() {
    return {
      sliceVal: 70,
      dots: "...",
    };
  },
  methods: {
    goToSolution(sol) {
      this.$emit("openSolution", sol);
    },
    expandData() {
      this.sliceVal = 1000;
      this.dots = "";
    },
    dispandData() {
      this.sliceVal = 70;
      this.dots = "...";
    },
  },
};
</script>

<style>
</style>