<template>
  <div class="techs-sec">
    <div class="container">
      <div class="row align-items-center">
        <div
          :class="[
            'col-lg-4',
            $activeLang == 'ar' ? 'text-lg-end' : 'text-lg-start',
            'mb-4',
          ]"
        >
          <div class="sec-title">{{ $t("ourTechnologies") }}</div>
          <div class="sec-desc">
            {{ $t("ourTechnologiesDesc") }}
          </div>
        </div>
        <div class="col-lg-8">
          <div class="slider-cont" dir="ltr">
            <div
              class="tech-slide"
              :style="`width: calc(80px * ${technologies.length})`"
              v-for="(technologie, technologieInd) in technologies"
              :key="technologieInd"
            >
              <div class="slide">
                <img
                  :src="technologie.logo"
                  :alt="technologie.name + ' Logo'"
                />
              </div>
            </div>
          </div>
          <div class="slider-cont" dir="rtl">
            <div
              class="tech-slide-2"
              :style="`width: calc(80px * ${technologies.length})`"
              v-for="(technologie, technologieInd) in revTechnologies"
              :key="technologieInd"
            >
              <div class="slide">
                <img
                  :src="technologie.logo"
                  :alt="technologie.name + ' Logo'"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper.min.css";
export default {
  components: {
    // Swiper,
    // SwiperSlide
  },
  data() {
    return {
      options: {
        slidesPerView: "auto",
        loop: true,
      },
      technologies: [
        {
          logo: require("../../assets/images/sap.png"),
          name: "SAP",
        },
        {
          logo: require("../../assets/images/linux.png"),
          name: "Linux",
        },
        {
          logo: require("../../assets/images/sql.png"),
          name: "MySQL",
        },
        {
          logo: require("../../assets/images/php.png"),
          name: "PHP",
        },
        {
          logo: require("../../assets/images/laravel.png"),
          name: "Laravel",
        },
        {
          logo: require("../../assets/images/lumen.png"),
          name: "Lumen",
        },
        {
          logo: require("../../assets/images/vue.png"),
          name: "Vue.js",
        },
        {
          logo: require("../../assets/images/html.png"),
          name: "HTML",
        },
        {
          logo: require("../../assets/images/css.png"),
          name: "CSS",
        },
        {
          logo: require("../../assets/images/sass.png"),
          name: "Sass",
        },
        {
          logo: require("../../assets/images/less.png"),
          name: "LESS",
        },
        {
          logo: require("../../assets/images/bs.png"),
          name: "Bootstrap",
        },
        {
          logo: require("../../assets/images/js.png"),
          name: "Javascript",
        },
        {
          logo: require("../../assets/images/elcjs.png"),
          name: "Electronjs ",
        },
        {
          logo: require("../../assets/images/postman.png"),
          name: "Postman  ",
        },
        {
          logo: require("../../assets/images/sweggr.png"),
          name: "Swagger",
        },
        {
          logo: require("../../assets/images/rest.png"),
          name: "REST",
        },
        {
          logo: require("../../assets/images/graphql.png"),
          name: "GraphQL",
        },
        {
          logo: require("../../assets/images/redis.png"),
          name: "Redis",
        },
        {
          logo: require("../../assets/images/varnish.png"),
          name: "Varnish",
        },
        {
          logo: require("../../assets/images/elstic.png"),
          name: "Elastic",
        },
        {
          logo: require("../../assets/images/aws.png"),
          name: "AWS",
        },
        {
          logo: require("../../assets/images/nginx.png"),
          name: "Nginx",
        },
        {
          logo: require("../../assets/images/Google-Cloud.png"),
          name: "Google Cloud",
        },
        {
          logo: require("../../assets/images/cloudflare.png"),
          name: "Cloudflare",
        },
        {
          logo: require("../../assets/images/android.png"),
          name: "Android",
        },
        {
          logo: require("../../assets/images/swift.png"),
          name: "Swift",
        },
        {
          logo: require("../../assets/images/java.png"),
          name: "Java",
        },
        {
          logo: require("../../assets/images/dart.png"),
          name: "Dart",
        },
        {
          logo: require("../../assets/images/flutter.png"),
          name: "Flutter",
        },
        {
          logo: require("../../assets/images/jira.png"),
          name: "Jira",
        },
        {
          logo: require("../../assets/images/slack.png"),
          name: "Slack",
        },
        {
          logo: require("../../assets/images/git.png"),
          name: "Gitlab",
        },
        {
          logo: require("../../assets/images/jenkins.png"),
          name: "Jenkins",
        },
        {
          logo: require("../../assets/images/sophos.png"),
          name: "Sophos",
        },
        {
          logo: require("../../assets/images/forticloud.png"),
          name: "FortiCloud",
        },
      ],
    };
  },
  computed: {
    revTechnologies() {
      const rev = [...this.technologies];
      return rev.reverse();
    },
  },
};
</script>

<style>
</style>