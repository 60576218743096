<template>
  <div :class="['pre-loader', $activeLang]">
    <!-- <div class="logo">
      <logo />
    </div>
    <h2 class="fw-bolder my-3">{{ $t("loading") }}...</h2>
    <div class="load-progress">
      <span></span>
      <span :style="{ width: value + '%' }"></span>
    </div>
    <div class="load-value">
      <span> {{ value }}</span> %
    </div> -->
    <video :src="require('../../assets/images/loader.mp4')" id="loaderVid" autoplay="true" muted="muted"></video>
  </div>
</template>

<script>
// import logo from "../../assets/images/loader-logo.svg";
export default {
  props: ["loadingValue"],
  // components: {
  //   logo,
  // },
  data() {
    return {
      // loading: true,
      // value: 0,
    };
  },
  // methods: {
  //   loaderDone(value) {
  //     this.$emit("loaderDone", value);
  //     this.$forceUpdate();
  //   },
  // },
  mounted() {
    const vidLoader = document.getElementById("loaderVid");
    vidLoader.play();
    vidLoader.addEventListener("ended", () => {
      this.$emit("loaderDone", true);
    });
    vidLoader.addEventListener("error", () => {
      this.$emit("loaderDone", true);
    });
    document.onkeydown = function (e) {
      if (event.keyCode == 123) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "I".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "C".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.shiftKey && e.keyCode == "J".charCodeAt(0)) {
        return false;
      }
      if (e.ctrlKey && e.keyCode == "U".charCodeAt(0)) {
        return false;
      }
    };
    // this.value = this.loadingValue;
    // const loader = setInterval(() => {
    //   this.value++;
    //   if (this.value >= 100) {
    //     clearInterval(loader);
    //     this.loaderDone(this.value);
    //     this.$forceUpdate();
    //   }
    // }, 30);
  },
  destroyed() {
    document.onkeydown = null;
  },
};
</script>

<style></style>
