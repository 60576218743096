<template>
  <div :class="['app-header', $activeLang]">
    <div class="scroller" :style="{ width: scrollValue + '%' }"></div>
    <header id="app-header">
      <div class="logo">
        <router-link :to="{ name: 'Home' }">
          <Logo />
        </router-link>
      </div>
      <ul class="links">
        <!-- <div class="backdrop" @click="toggleMenu"></div> -->
        <router-link :to="{ name: 'Home' }" class="d-block d-lg-none">
          <Logo />
        </router-link>
        <router-link v-for="(link, linkInd) in links" :key="linkInd" :to="{ name: link.link }" exact
          active-class="active" class="link">
          <li>{{ link.name }}</li>
        </router-link>
        <router-link :to="{ name: 'ContactUs' }" class="link d-lg-none" exact active-class="active">{{ $t("contactUs")
        }}</router-link>
        <!-- <SocialLinks class="d-lg-none mt-auto mb-5 pb-3 border-top w-100" /> -->
      </ul>
      <div class="contact-btn d-flex">
        <router-link :to="{ name: 'ContactUs' }" class="btn-pri-light d-none d-lg-block">{{ $t("contactUs") }}
        </router-link>
        <!-- <button
          class="
            btn-pri-dark
            mx-1
            text-capitalize
            d-flex
            align-items-center
            ar
            lang-changer
          "
          @click="openLanguages($event)"
        >
          <img
            :src="
              require(`../../assets/images/${
                $activeLang ? $activeLang : 'en'
              }.png`)
            "
            :alt="$activeLang + ' ' + 'Flag'"
          />
          <span class="mx-2 lang-name">{{ lang ? lang : $t("en") }}</span>
          <transition
            :enter-active-class="
              $activeLang == 'ar'
                ? 'animate__animated animate__backInLeft'
                : 'animate__animated animate__backInRight'
            "
            :leave-active-class="
              $activeLang == 'ar'
                ? 'animate__animated animate__backOutLeft'
                : 'animate__animated animate__backOutRight'
            "
          >
            <ul v-if="langsMenu" class="langs-list">
              <li
                @click="changeLang('ar')"
                class="lang-item d-flex align-items-center"
                v-if="$activeLang != 'ar'"
              >
                <img
                  :class="$activeLang && $activeLang == 'ar' ? 'ms-1' : 'me-1'"
                  :src="require(`../../assets/images/ar.png`)"
                  alt="Egypt Flag"
                />
                {{ $t("ar") }}
              </li>
              <li
                @click="changeLang('en')"
                class="lang-item d-flex align-items-center"
                v-if="$activeLang && $activeLang != 'en'"
              >
                <img
                  :class="$activeLang && $activeLang == 'ar' ? 'ms-1' : 'me-1'"
                  :src="require(`../../assets/images/en.png`)"
                  alt="Egypt Flag"
                />
                {{ $t("en") }}
              </li>
              <li
                @click="changeLang('de')"
                class="lang-item d-flex align-items-center"
                v-if="$activeLang != 'de'"
              >
                <img
                  :class="$activeLang && $activeLang == 'ar' ? 'ms-1' : 'me-1'"
                  :src="require(`../../assets/images/de.png`)"
                  alt="Egypt Flag"
                />
                {{ $t("de") }}
              </li>
            </ul>
          </transition>
        </button> -->
        <button class="d-flex d-lg-none btn closer" @click="toggleMenu($event)">
          <span></span>
          <span></span>
          <span></span>
        </button>
      </div>
    </header>
  </div>
</template>

<script>
import Logo from "../misc/logo.vue";
// import SocialLinks from "../misc/SocialLinks.vue";
export default {
  props: ["scrollValue"],
  components: {
    Logo,
    // SocialLinks,
  },
  data() {
    return {
      lang: this.$t(this.$activeLang || this.osLang),
      langsMenu: false,
      links: [
        {
          name: this.$t("home"),
          link: "Home",
        },
        {
          name: this.$t("about"),
          link: "AboutUs",
        },
        {
          name: this.$t("services"),
          link: "Services",
        },
        {
          name: this.$t("portfolio"),
          link: "Portfolio",
        },
        {
          name: this.$t("ourProcess"),
          link: "OurProcess",
        },
      ],
    };
  },
  methods: {
    toggleMenu(e) {
      const menu = document.querySelector(".links");
      menu.classList.toggle("active");
      e.target.classList.toggle("active");
      if (e.target.classList.contains("active")) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflow = "auto";
      }
    },
    changeLang(lang) {
      localStorage.setItem("lang", lang);
      window.location.reload();
    },
    openLanguages(e) {
      if (e.target.nodeName == "BUTTON") {
        this.langsMenu = !this.langsMenu;
      } else {
        return false;
      }
    },
  },
  mounted() {
    const header = document.getElementById("app-header");
    document.addEventListener("click", (e) => {
      if (
        e.target.classList.contains("langs-list") ||
        e.target.classList.contains("lang-item") ||
        e.target.classList.contains("lang-changer")
      ) {
        return false;
      } else {
        this.langsMenu = false;
      }
    });
    if (header) {
      window.addEventListener("scroll", () => {
        if (window.scrollY >= 100) {
          header.classList.add("active");
        } else {
          header.classList.remove("active");
        }
      });
    }
  },
  watch: {
    $route(nr) {
      if (nr) {
        const menu = document.querySelector(".links");
        document.querySelector(".closer").classList.remove("active");
        menu.classList.remove("active");
        document.body.style.overflow = "auto";
      }
    },
  },
};
</script>

<style >
.animate__animated.animate__backInLeft,
.animate__animated.animate__backInRight {
  --animate-duration: 0.6s;
}

.animate__animated.animate__backOutRight,
.animate__animated.animate__backOutLeft {
  --animate-duration: 1.25s;
}
</style>