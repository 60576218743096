<template>
  <div
    :class="[
      'actions',
      'align-items-center',
      small ? sm : md,
      forTabs ? 'for-tabs' : '',
    ]"
  >
    <button class="action mx-1" @click="prev">
      <fa
        :icon="['fa', $activeLang == 'ar' ? 'chevron-right' : 'chevron-left']"
      />
    </button>
    <button class="action mx-1" @click="next">
      <fa
        :icon="['fa', $activeLang == 'ar' ? 'chevron-left' : 'chevron-right']"
      />
    </button>
  </div>
</template>

<script>
export default {
  props: ["small", "forTabs"],
  methods: {
    next() {
      this.$emit("next");
    },
    prev() {
      this.$emit("prev");
    },
  },
  computed: {
    sm() {
      return ["d-flex", "d-md-none", "mt-5", "mx-auto"];
    },
    md() {
      return ["d-md-flex", "d-none"];
    },
  },
};
</script>

<style>
</style>