<template>
  <div class="how-it-works">
    <div class="blob-2">
      <img src="../../assets/images/blob-2.png" alt="Image" />
    </div>
    <div class="container">
      <div class="content text-center text-md-start">
        <div class="header py-5 mt-5 text-center">
          <h2 class="sec-title">{{ $t("yourtrustedtechnologypartner") }}</h2>
          <p class="sec-desc">
            {{ $t("yourTuresedtechnologyPartnerDesc") }}
          </p>
        </div>
        <div class="row boxs my-4 pb-5 px-3">
          <How-it-works-card
            v-for="(cardData, index) in cardsData"
            :dataCard="cardData"
            :key="index"
            :back="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import HowItWorksCard from "./HowItWorksCard.vue";
export default {
  components: { HowItWorksCard },
  data() {
    return {
      cardsData: [
        {
          img: require("../../assets/images/box.png"),
          title: this.$t("getInTouch"),
          description: this.$t("getInTouchDesc"),
        },
        {
          img: require("../../assets/images/calendar.png"),
          title: this.$t("scheduleAppointment"),
          description: this.$t("scheduleAppointmentDesc"),
        },
        {
          img: require("../../assets/images/statistics.png"),
          title: this.$t("growTogether"),
          description: this.$t("growTogetherDesc"),
        },
      ],
    };
  },
};
</script>