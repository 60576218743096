// import ar from "./assets/locales/ar.json";
import en from "./assets/locales/en.json";
// import de from "./assets/locales/de.json";
import VueI18n from "vue-i18n";
import Vue from "vue";
// import mixins from "./mixins";

Vue.use(VueI18n);
export default new VueI18n({
    locale: localStorage.getItem("lang") || navigator.language ? navigator.language.split('-')[0] : '' || navigator.userLanguage ? navigator.userLanguage.split('-')[0] : '',
    messages: {
        en,
    }
});