<template>
  <div :class="['about-us-sec', $activeLang]">
    <div class="blob-3">
      <img src="../../assets/images/blob-3.png" alt="blob" />
    </div>
    <div class="container">
      <div class="row">
        <div class="col-md-7 details">
          <div class="sec-title">{{ $t("about") }}</div>
          <div class="sec-desc">
            {{ $t("aboutUsDesc") }}
          </div>
          <Feat
            :logo="require('../../assets/images/shield.png')"
            :title="$t('safeAndSecured')"
            :desc="$t('safeAndSecuredDesc')"
          />
          <Feat
            :logo="require('../../assets/images/rating.png')"
            :title="$t('highlyExpertTeam')"
            :desc="$t('highlyExpertTeamDesc')"
          />
          <Feat
            :logo="require('../../assets/images/shield.png')"
            :title="$t('ourMagicalFormula')"
            :desc="$t('ourMagicalFormulaDesc')"
          />
        </div>
        <div class="image col-md-5">
          <img src="../../assets/images/robot.jpg" alt="ROBOt" />
          <div class="statics">
            <img src="../../assets/images/statics.png" alt="statics" />
            <div class="medal">
              <img src="../../assets/images/medal.png" alt="medal" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Feat from "../misc/Feat.vue";
export default {
  components: { Feat },
};
</script>

<style>
</style>