<template>
  <div class="services-sec">
    <div class="container py-4">
      <div class="sec-header d-flex justify-content-between align-items-center">
        <div class="sec-title">{{ $t("ourServices") }}</div>
        <Swiper-actions @next="next" @prev="prev" :small="false" />
      </div>
      <div class="sec-body">
        <Swiper
          :dir="$activeLang == 'ar' ? 'rtl' : 'ltr'"
          ref="mySwiper"
          :options="swiperOptions"
        >
          <Swiper-slide v-for="(service, serind) in services" :key="serind">
            <ServiceCard :service="service" @openService="toggelModal" />
          </Swiper-slide>
        </Swiper>
        <Swiper-actions @next="next" @prev="prev" :small="true" />
      </div>
    </div>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <Modal
        :service="modalData"
        v-if="activeModal"
        @closeModal="toggelModal"
      />
    </transition>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.min.css";
import ServiceCard from "./ServiceCard.vue";
import Modal from "../../components/Layout/Modal.vue";
import SwiperActions from "./SwiperActions.vue";
import { mapGetters } from "vuex";

export default {
  components: {
    Swiper,
    SwiperSlide,
    ServiceCard,
    Modal,
    SwiperActions,
  },
  data() {
    return {
      swiperOptions: {
        loop: true,
        breakpoints: {
          480: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // when window width is >= 480px
          758: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          992: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          1200: {
            spaceBetween: 20,
            slidesPerView: 4,
          },
        },
      },
      modalData: null,
      activeModal: false,
      // link: null,
      // btnName: null,
    };
  },

  computed: {
    ...mapGetters(["services"]),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    },
  },
  mounted() {
    if (this.swiper) {
      setInterval(() => {
        this.swiper.slideNext(800);
      }, 6000);
    }
  },
  methods: {
    next() {
      this.swiper.slideNext(800);
    },
    prev() {
      this.swiper.slidePrev(800);
    },
    toggelModal(ser) {
      this.activeModal = !this.activeModal;
      if (ser) {
        this.modalData = ser;
        // this.btnName = "See Use Cases";
        // this.link = {
        //   name: "HowToDeliver",
        // };
      } else {
        console.log(ser);
      }
    },
  },
};
</script>

<style>
</style>