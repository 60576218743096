<template>
  <div class="app-footer" id="appFooter">
    <div class="container">
      <div class="row content justify-content-between">
        <div class="col-md-4 col-12 info">
          <div class="logo mb-4">
            <router-link :to="{ name: 'Home' }" @click.native="goTop">
              <logo />
            </router-link>
          </div>
          <p> {{ $t("introDesc") + " " + $t("introLongDesc") }} </p>
          <!-- <SocialLinks /> -->
        </div>
        <div class="col-md-8 col-12 links mt-5 mt-md-0">
          <div class="row justify-content-end">
            <div class="col-md-3 col-12 p-md-0" v-for="(col, colInd) in cols" :key="colInd">
              <h5 class="col-title">{{ col.title }}</h5>
              <ul>
                <li v-for="(link, linkInd) in col.links" :key="linkInd">
                  <router-link :to="{ name: link.link }" class="link" exact active-class="active">{{ link.name }}
                  </router-link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="copy-rights">© {{ year }} Optivius Software Solutions. {{ $t('rights') }}  </div>
  </div>
</template>

<script>
import Logo from "../misc/logo.vue";
// import SocialLinks from "../misc/SocialLinks.vue";
export default {
  components: {
    Logo,
    // SocialLinks,
  },
  data() {
    return {
      socialLinks: [
        {
          name: "Facebook Account",
          link: "https://www.facebook.com",
          icon: ["fab", "facebook"],
        },
        {
          name: "Instagram Account",
          link: "https://www.instagram.com",
          icon: ["fab", "instagram"],
        },
        {
          name: "Twitter Account",
          link: "https://www.twitter.com",
          icon: ["fab", "twitter"],
        },
        {
          name: "LinkedIn Account",
          link: "https://www.linkedind.com",
          icon: ["fab", "linkedin"],
        },
      ],
      cols: {
        quickLinks: {
          title: this.$t("quickLinks"),
          links: [
            {
              name: this.$t("home"),
              link: "Home",
            },
            {
              name: this.$t("services"),
              link: "Services",
            },
            {
              name: this.$t("solutions"),
              link: "Solutions",
            },
            {
              name: this.$t("portfolio"),
              link: "Portfolio",
            },
          ],
        },
        companyLinks: {
          title: this.$t("company"),
          links: [
            {
              name: this.$t("about"),
              link: "AboutUs",
            },
            {
              name: this.$t("howToDeliver"),
              link: "OurProcess",
            },
            {
              name: this.$t("contactUs"),
              link: "ContactUs",
            },
            // {
            //   name: this.$t("careers"),
            //   link: "Careers",
            // },
          ],
        },
        information: {
          title: this.$t("inforamtion"),
          links: [
            {
              name: this.$t("privacy"),
              link: "PrivacyPolicy",
            },
            {
              name: this.$t("terms"),
              link: "TermsCondition",
            },
            {
              name: this.$t("cookiesPolicy"),
              link: "cookiesPolicy",
            },
          ],
        },
      },
    };
  },
  methods: {
    goTop() {
      if (this.$route.name == "Home") {
        window.scrollTo(0, 0);
      }
    },
  },
  computed: {
    year() {
      const date = new Date().getFullYear();
      return date;
    },
    appName() {
      return this.$store.state.appName;
    },
  },
};
</script>

<style>
</style>