<template>
  <div class="modal-container">
    <transition-group
      enter-active-class="animate__animated animate__fadeInUp"
      leave-active-class="animate__animated animate__fadeOutDown"
      mode="out-in"
    >
      <div class="data-box" v-if="opened && service" key="one">
        <fa
          :icon="['fa', 'times']"
          size="sm"
          @click="closeModal"
          class="closer pointer"
        />

        <div class="row">
          <div class="col-lg-6" v-if="service.img">
            <div class="image">
              <img :src="service.img" alt="image" />
            </div>
          </div>
          <div :class="service.img ? 'col-lg-6' : 'col-12'">
            <h2
              class="sec-title d-flex justify-content-between align-items-start"
            >
              <span>
                {{ service.title }}
              </span>
            </h2>

            <div class="info">
              <p class="sec-desc">
                {{ service.desc }}
              </p>
              <!-- <router-link :to="link">
            <button class="btn-pri-light">{{ btnName }}</button>
          </router-link> -->
            </div>
          </div>
        </div>
        <!-- </div> -->
        <!-- </div> -->
      </div>
      <!-- <div class="data-box" v-if="opened && project" key="tow">
        <div
          class="sec-title d-flex justify-content-between align-items-center"
        >
          {{ project.title }}
          <fa :icon="['fa', 'times']" size="sm" @click="closeModal" />
        </div>
        <div class="row mt-2 mt-lg-4">
          <div class="col-lg-6 col-12">
            <div class="images">
              <swiper class="swiper" ref="imgsSwiper" :options="swiperOptions">
                <swiper-slide
                  v-for="(img, imgInd) in project.imgs"
                  :key="imgInd"
                >
                  <img :src="img" :alt="project.title" class="img-fit-h" />
                </swiper-slide>
                <div
                  class="
                    swiper-pagination swiper-pagination-bullets
                    w-100
                    d-flex
                    justify-content-center
                    align-items-center
                    my-3
                  "
                  slot="pagination"
                >
                  <span
                    class="swiper-pagination-bullet-custom"
                    v-for="bol in project.imgs.length"
                    :key="bol"
                  ></span>
                </div>
              </swiper>
            </div>
          </div>
          <div class="col-lg-6 col-12">
            <div class="info">
              <p class="sec-desc">
                {{ project.desc }}
              </p>
              <router-link :to="link" v-if="link.name">
                <button class="btn-pri-light">{{ btnName }}</button>
              </router-link>
              <a :href="link" v-else target="_blank">
                <button class="btn-pri-light">{{ btnName }}</button>
              </a>
            </div>
          </div>
        </div>
      </div> -->
    </transition-group>
  </div>
</template>

<script>
// import { Swiper, SwiperSlide } from "vue-awesome-swiper";
// import "swiper/swiper.min.css";
export default {
  // components: {
  //   Swiper,
  //   SwiperSlide,
  // },
  props: ["service", "btnName", "link", "project"],
  data() {
    return {
      opened: false,
      swiperOptions: {
        centeredSlides: true,
        cursor: "grap",
        slidesPerView: "auto",
        loop: true,
        pagination: {
          el: ".swiper-pagination",
        },
      },
    };
  },
  mounted() {
    document.body.style.overflow = "hidden";
    setTimeout(() => {
      this.opened = true;
    }, 200);
    if (this.swiper) {
      setInterval(() => {
        this.swiper.slideNext(400);
      }, 4000);
    }
  },
  // beforeDestroy() {
  //   this.opened = false;
  //   document.body.style.overflow = "auto";
  // },
  computed: {
    swiper() {
      if (this.opened) {
        return this.$refs.imgsSwiper.$swiper;
      } else {
        return false;
      }
    },
  },
  methods: {
    closeModal() {
      this.opened = false;
      setTimeout(() => {
        document.body.style.overflow = "auto";
        this.$emit("closeModal");
      }, 300);
    },
  },
};
</script>
<style scoped lang="scss">
.swiper {
  .swiper-pagination-bullet-custom {
    $size: 10px;
    margin: 0 2.5px;
    width: $size !important;
    height: $size !important;
    line-height: $size !important;
    text-align: center;
    color: #000;
    opacity: 0.7;
    background: rgba(#000, 0.2);
    transition: all 0.5s;
    border-radius: 50%;
    background: #41c4de;
  }
}
</style>