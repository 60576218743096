<template>
  <div class="solutions-section">
    <div class="container">
      <div class="sec-header d-flex justify-content-between align-items-center">
        <div>
          <div class="sec-title">{{ $t("ourSolutions") }}</div>
          <!-- <p class="sec-desc">
            {{ $t("testTxt") }}
            {{ $t("testTxt") }}
          </p> -->
        </div>

        <Swiper-actions :small="false" @next="next" @prev="prev" />
      </div>
      <Swiper
        :dir="$activeLang == 'ar' ? 'rtl' : 'ltr'"
        ref="solSwiper"
        :options="swiperOptions"
        v-if="swiperStart"
      >
        <Swiper-slide
          class="sol-swiper-slide"
          v-for="(solution, testiInd) in solutions"
          :key="testiInd"
        >
          <Solution-card :solution="solution" @openSolution="toggelModal" />
        </Swiper-slide>
      </Swiper>
      <!-- <router-link :to="{ name: 'Solutions' }">
        <button class="btn-pri-light mx-auto my-3 d-block">
          All Solutions
        </button>
      </router-link> -->
      <Swiper-actions :small="true" @next="next" @prev="prev" />
    </div>
    <transition
      enter-active-class="animate__animated animate__fadeIn"
      leave-active-class="animate__animated animate__fadeOut"
      mode="out-in"
    >
      <Modal
        :service="modalData"
        v-if="activeModal"
        @closeModal="toggelModal"
      />
    </transition>
  </div>
</template>

<script>
import SolutionCard from "./SolutionCard.vue";
import { Swiper, SwiperSlide } from "vue-awesome-swiper";
import "swiper/swiper.min.css";
import SwiperActions from "./SwiperActions.vue";
import { mapGetters } from "vuex";
import Modal from "./Modal.vue";
export default {
  components: { SolutionCard, Swiper, SwiperSlide, SwiperActions, Modal },
  data() {
    return {
      swiperOptions: {
        slidesPerView: "auto",
        loop: true,
      },
      swiperStart: false,
      modalData: null,
      activeModal: false,
    };
  },
  mounted() {
    setInterval(() => {
      this.next();
    }, 8000);
    setTimeout(() => {
      this.swiperStart = true;
    }, 200);
  },
  computed: {
    ...mapGetters(["solutions"]),
    swiper() {
      return this.$refs.solSwiper.$swiper;
    },
  },
  methods: {
    next() {
      this.swiper.slideNext(800);
    },
    prev() {
      this.swiper.slidePrev(800);
    },
    toggelModal(ser) {
      this.activeModal = !this.activeModal;
      if (ser) {
        this.modalData = ser;
      }
    },
  },
};
</script>

<style lang="scss">
</style>