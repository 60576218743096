<template>
  <div :class="['news-letter-sec', $activeLang]" id="bookCons">
    <div class="blob-3">
      <img src="../../assets/images/blob-4.png" alt="blob- 4" />
    </div>
    <div class="container">
      <div
        class="row news-card align-items-center"
        :style="`background-image: url(${require('../../assets/images/Group.png')})`"
      >
        <div class="col-12 p-4">
          <div class="sec-title">{{ $t("freeConsAsk") }}</div>
          <div class="sec-desc fw-bold">
            {{ $t("freeConsAskDesc") }}
          </div>
          <button
            class="btn-pri-dark mt-3 px-3"
            @click="$router.push({ name: 'ContactUs' })"
          >
            {{ $t("bookFreeCons") }}
          </button>
          <!-- <form @submit.prevent="subscribe">
            <input
              type="email"
              :placeholder="$t('emailPlaceholder')"
              required
            />
          </form> -->
        </div>
        <!-- <div class="col-lg-4 d-none d-lg-block">
          <img src="../../assets/images/news-man.png" alt="Man" />
        </div> -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {},
};
</script>

<style>
</style>