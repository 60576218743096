export default {
    computed: {
        osLang() {
            if (navigator.language) {
                return navigator.language.split("-")[0];
            } else {
                return navigator.userLanguage.split("-")[0];
            }
        },
        // currentLocale() {
        //     return 
        // }
    }
}