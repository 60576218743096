<template>
  <div
    :class="[
      'col-md-4',
      'my-1',
      'mx-auto',
      'box',
      back ? 'with-back' : '',
      $activeLang == 'ar' ? 'text-md-end' : 'text-md-start',
    ]"
  >
    <div class="icon mx-auto mx-md-0">
      <img :src="dataCard.img" alt="icon" />
    </div>
    <div class="title">{{ dataCard.title }}</div>
    <div class="desc">
      {{ dataCard.description }}
    </div>
  </div>
</template>

<script>
export default {
  props: ["dataCard", "back"],
};
</script>

<style>
</style>