import VueRouter from "vue-router";
import Vue from "vue";
import Home from "../views/Home.vue";
Vue.use(VueRouter);
const routes = [
    {
        path: "/",
        name: "Home",
        component: Home,
    },
    {
        path: "*",
        name: "404",
        component: () => import('../components/misc/404.vue')
    },
    {
        path: "/about-us",
        name: "AboutUs",
        component: () => import("../views/AboutUs.vue")
    },
    {
        path: "/services",
        name: "Services",
        component: () => import("../views/Services.vue")
    },
    {
        path: "/solutions/:id/:title",
        name: "SolutionDetails",
        params: {
            id: ''
        },
        component: () => import("../views/SolutionDetails.vue")
    },
    {
        path: "/portfolio",
        name: "Portfolio",
        component: () => import("../views/Portfolio.vue")
    },
    {
        path: "/solutions",
        name: "Solutions",
        component: () => import("../views/Solutions.vue")
    },
    {
        path: "/contact-us",
        name: "ContactUs",
        component: () => import("../views/ContactUs.vue")
    },
    {
        path: "/our-process",
        name: "OurProcess",
        component: () => import("../views/HowToDeliver.vue")
    },
    {
        path: "/portfolio/project-details/:id",
        name: "ProjectDetails",
        component: () => import("../views/ProjectDetails.vue")
    },
    {
        path: "/careers",
        name: "Careers",
    },
    {
        path: "/privacy-policy",
        name: "PrivacyPolicy",
    },
    {
        path: "/terms-condition",
        name: "TermsCondition",
    },
    {
        path: "/faq",
        name: "FAQ",
    },
    {
        path: "/testimonials",
        name: "Testimonials",
    },
    {
        path: "/cookies",
        name: "cookiesPolicy",
    },
];

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    scrollBehavior() {
        return { x: 0, y: 0 };
    },
});
export default router;
