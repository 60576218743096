<template>
  <div class="vedio-player">
    <div class="backdrop"></div>
    <transition
      enter-active-class="animate__animated animate__fadeInUp"
      leave-active-class="animate__animated animate__fadeOutDown"
    >
      <div class="vedio" v-if="start">
        <div class="colser" @click="close">
          <fa :icon="['fa', 'times']" />
        </div>
        <video
          src="../../assets/images/intro.mp4"
          controls
          contenteditable
          class="w-100"
        ></video>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  emits: ["close"],
  data() {
    return {
      start: false,
    };
  },
  methods: {
    close() {
      this.start = false;
      setTimeout(() => {
        this.$emit("close");
      }, 400);
    },
  },
  mounted() {
    setTimeout(() => {
      this.start = true;
    }, 100);
    document.body.style.overflow = "hidden";
    // document.body.style.marginRight = "17px";
  },
  destroyed() {
    document.body.style.overflow = "auto";
    // document.body.style.marginRight = "0";
  },
};
</script>

<style></style>
