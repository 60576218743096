<template>
  <div>
    <div class="blob">
      <blob />
    </div>
    <transition name="fade">
      <Loader v-if="loaderActive" :loadingValue="loadingValue" @loaderDone="removeLoader" />
    </transition>
    <AppHeader :scrollValue="scrollValue" />
    <div id="container">
      <transition enter-active-class="animate__animated animate__fadeIn"
        leave-active-class="animate__animated animate__fadeOut" mode="out-in">
        <router-view v-slot="Component">
          <component :is="Component"></component>
        </router-view>
      </transition>
    </div>
    <AppFooter />
  </div>
</template>

<script>
import AppHeader from "./components/Navigation/AppHeader.vue";
import AppFooter from "./components/Navigation/AppFooter.vue";
import blob from "./assets/images/blob.svg";
import Loader from "./components/misc/Loader.vue";

export default {
  components: {
    AppHeader,
    AppFooter,
    blob,
    Loader,
  },
  data() {
    return {
      scrollValue: 0,
      loaderActive: true,
      loadingValue: 0,
    };
  },
  methods: {
    removeLoader(val) {
      if (val) {
        this.loaderActive = false;
        document.body.style.overflow = "auto";
        this.$forceUpdate();
      }
    },
  },
  name: "App",
  mounted() {
    window.addEventListener("scroll", () => {
      this.scrollValue =
        (window.scrollY * 100) / (document.body.clientHeight - 850);
    });
    document.body.classList.add(this.$activeLang || this.osLang);
    if (this.loaderActive) {
      document.body.style.overflow = "hidden";
    }
  },
  watch: {
    loaderActive(loader) {
      if (loader == true) {
        document.body.style.overflow = "hidden";
      } else {
        document.body.style.overflowY = "auto";
        document.body.style.overflowX = "hidden";
      }
    },
  },
};
</script>

<style lang="scss">
@import "./assets/scss/index.scss";
@import url("https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Almarai:wght@300;400;700;800&display=swap");

.animate__animated.fadeIn {
  --animation-duration: 0.1s !important;
}

.animate__animated.fadeInUp {
  --animation-duration: 0.1s !important;
}

.animate__animated.fadeOutDown {
  --animation-duration: 0.1s !important;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-active,
.fade-leave-active {
  transition: all 0.3s ease-out;
}
</style>
